const timeZoneOffset = "GMT+05:30";
Date.prototype.toIsoString = function () {
  var tzo = -this.getTimezoneOffset(),
    dif = tzo >= 0 ? "+" : "-",
    pad = function (num) {
      var norm = Math.floor(Math.abs(num));
      return (norm < 10 ? "0" : "") + norm;
    };
  return (
    this.getFullYear() +
    "-" +
    pad(this.getMonth() + 1) +
    "-" +
    pad(this.getDate()) +
    "T" +
    pad(this.getHours()) +
    ":" +
    pad(this.getMinutes()) +
    ":" +
    pad(this.getSeconds()) +
    dif +
    pad(tzo / 60) +
    ":" +
    pad(tzo % 60)
  );
};

export function getCurrentISOTime() {
  const today = new Date().toIsoString();
  return today.split("T")[1].split(".")[0];
}

export function getCurrentISODate() {
  const today = new Date().toIsoString();
  return today.split("T")[0];
}

export function getCurrentTime() {
  return new Date().toLocaleTimeString("en-IN");
}

export function getCurrentDate() {
  const today = new Date().toIsoString();
  return today.split("T")[0].replace("-", "").replace("-", "");
}

export function getCurrentDateWithSlash() {
  const today = new Date().toIsoString();
  return today.split("T")[0].replace("-", "/").replace("-", "/");
}
export function getCurrentDateWithDash() {
  const today = new Date().toIsoString();
  return today.split("T")[0];
}

export function getFormattedDate(date) {
  const d = new Date(date);
  let month = (d.getMonth() + 1).toString();
  let day = d.getDate().toString();
  let year = d.getFullYear().toString();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export function getFormattedDateWithSlash(date) {
  let d = new Date(date);
  if (!date) d = new Date();

  let month = (d.getMonth() + 1).toString();
  let day = d.getDate().toString();
  let year = d.getFullYear().toString();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("/");
}

export function getFormattedTime(dateTime) {
  const d = new Date(dateTime);
  const time = d.toIsoString().split("T")[1].split("+")[0];
  console.log("Time at formate: ");
  const t = time.split(":");
  let tstr = "";
  if (t[0] > 12)
    tstr =
      (parseInt(t[0]) - 12).toString().padStart(2, "0") + ":" + t[1] + " PM";
  else if (t[0] == 12) tstr = "12:" + t[1] + " PM";
  else tstr = t[0] + ":" + t[1] + " AM";
  return tstr;
}
export function getHoursFromTime(time) {
  let ts = time.split(" ");
  let isAM = ts[1] == "AM";
  if (isAM) return ts[0].split(":")[0];
  let tint = parseInt(ts[0].split(":")[0]);
  return (tint == 12 ? 12 : tint + 12).toString();
}

export function extractTime(dateTime) {
  const d = new Date(dateTime);
  return d.toIsoString().split("T")[1].split("+")[0];
}
export function getCurrentFormattedDateTime() {
  const d = new Date().toIsoString().split("+")[0].replace("T", " ");
  return d;
}
export function getFormattedDateTime(dt) {
  const d = new Date(dt).toIsoString().split("+")[0].replace("T", " ");
  return d;
}
export function getLocalFormattedDateTime() {
  const d = new Date()
    .toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata",
      hour12: false,
    })
    .replace(", ", " ")
    .replace("/", "-")
    .replace("/", "-");
  return d;
}

export function getCaseNumberFormatedDate(date) {
  //With Slash 05/12/21
  console.log(date);
  console.log("getCaseNumberFormatedDate");
  if (date !== undefined) {
    const d = new Date(date);
    let month = (d.getMonth() + 1).toString();
    let day = d.getDate().toString();
    let year = d.getFullYear().toString();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    let str = [day, month, year.slice(2, 4)].join("/");
    console.log(str);
    return str;
  }
}
export function getCaseNumberFormatedDateString(date) {
  //plain string 051221
  console.log(date);
  console.log("getCaseNumberFormatedDateString");
  if (date !== undefined) {
    const d = new Date(date);
    let month = (d.getMonth() + 1).toString();
    let day = d.getDate().toString();
    let year = d.getFullYear().toString();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    let str = [day, month, year.slice(2, 4)].join("");
    console.log(str);
    return str;
  }
}
