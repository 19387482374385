import { toast } from "react-toastify";
const camera = (function () {
  let width = 0;
  let height = 0;
  let shouldFaceUser = "environment";
  const createObects = function (showWhat) {
    let video = document.createElement("video");
    video.id = "video";
    video.width = width;
    video.height = height;
    video.autoplay = true;
    //document.body.appendChild(video);
    if (!document.getElementById("video"))
      document.getElementById("videoContainer").appendChild(video);
    let canvas = null;
    canvas = document.createElement("canvas");
    canvas.id = "canvas2";
    canvas.width = 42;
    canvas.height = 24;
    console.log(showWhat);
    if (showWhat.includes("Registration")) {
      canvas.id = "canvas1";
    }
    console.log(document.getElementById(showWhat));
    console.log(canvas);
    document.getElementById(showWhat).appendChild(canvas);
  };

  return {
    video: null,
    context: null,
    canvas: null,
    startCamera: function (showWhat) {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        width = 640;
        height = 480;
        createObects(showWhat);

        this.video = document.getElementById("video");
        this.canvas = document.getElementById("canvas2");
        if (showWhat.includes("Registration"))
          this.canvas = document.getElementById("canvas1");

        (function (video) {
          navigator.mediaDevices
            .getUserMedia({
              video: { facingMode: shouldFaceUser },
              audio: false,
            })
            .then(function (stream) {
              video.srcObject = stream;
              video.play();
            })
            .catch((e) => toast.error(e + "Browser Not Supported"));
        })(this.video);
      }
    },
    stopCam: function () {
      const video = document.getElementById("video");
      if (video !== null) {
        const stream = video.srcObject;
        const tracks = stream.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });
        video.remove();
        video.srcObject = null;
      }
    },
    switchCamera: function (showWhat) {
      let supports = navigator.mediaDevices.getSupportedConstraints();
      toast.info(JSON.stringify(supports));
      this.stopCam();
      // toggle \ flip
      shouldFaceUser = shouldFaceUser === "user" ? "environment" : "user";
      this.startCamera(showWhat);
    },
    takeSnapshot: function () {
      // Get the exact size of the video element.
      const width = this.canvas.width; //this.video.width;
      const height = this.canvas.height; //this.video.height;
      // Set the canvas to the same dimensions as the video.

      this.context = this.canvas.getContext("2d");
      // Draw the current frame from the video on the canvas.
      this.context.drawImage(this.video, 0, 0, width, height);

      // Get an image dataURL from the canvas.
      return this.canvas.toDataURL("image/png");
    },
  };
})();

export default camera;
