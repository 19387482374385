import React, { Component } from "react";

class CookieBanner extends Component {
  state = {
    showCookieBanner: false,
  };
  componentDidMount = () => {
    let cookiePermission = null;
    try {
      cookiePermission = window.localStorage;
    } catch (e) {
      this.setState({ showCookieBanner: true });
    }
  };
  render() {
    return (
      <div>
        {this.state.showCookieBanner ? (
          <div style={{ background: "#E8605F", textAlign: "center" }}>
            Some Features may not work, Please Allow Third Party Cookie
            Permisssion.
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default CookieBanner;
