import React, { Component, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Spinner from "react-bootstrap/Spinner";
import "../../assets/css/loadingSpinner.css";
// import { LoadingFiles } from "../../store/filesDux";
// import { LoadingUser } from "../../store/userDux";

const LoadingSpinner = ({ loading }) => {
  // const loadFiles = LoadingFiles();
  // const loadUser = LoadingUser();

  return (
    <>
      <div
        className={
          // loadFiles ||
          loading ? "loadingSpinner" : "loadingSpinner hideLoading"
        }
      >
        <Spinner animation="border" />
      </div>
    </>
  );
};

export default LoadingSpinner;
