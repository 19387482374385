import "./App.css";
import "./assets/css/index.css";
import "./assets/css/mednetStyles.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.css";
import { ToastContainer } from "react-toastify";
import CookieBanner from "./Components/Navbar/CookieBanner";
import Navbar from "./Components/Navbar/Navbar.jsx";
import Footer from "./Components/Navbar/Footer.jsx";
import { Switch, Route, Redirect } from "react-router-dom";
import React, { Component } from "react";
import FacilityRegistrationPage from "./Components/RegisterFacility/FacilityRegisterPage.jsx";
import AdminPage from "./Components/AdminLogin/AdminPage.jsx";
import HomePage from "./Components/Home/HomePage.jsx";
import UserProfile from "./Components/User/UserProfile.jsx";
import ApplicationSent from "./Components/Home/ApplicationSent.jsx";
import TermsOfUse from "./Components/TermsOfUse.jsx";
import PrivacyPolicy from "./Components/PrivacyPolicy.jsx";
import Careers from "./Components/Careers.jsx";
import Login from "./Components/User/loginPage.jsx";
import Logout from "./Components/User/Logout.jsx";
import FacilityRegistered from "./Components/RegisterFacility/FacilityRegistered.jsx";
import auth from "./services/authService.js";
import ResetPassword from "./Components/User/ResetPassword.jsx";
import ResetPasswordAuth from "./Components/User/ResetPasswordAuth.jsx";
class App extends Component {
  constructor() {
    super();
    this.AppRef = React.createRef();
    //to disable scroll inside input number box
    document.addEventListener("wheel", function (event) {
      if (document.activeElement.type === "number") {
        document.activeElement.blur();
      }
    });
  }
  state = {
    rolesToMedical: ["Admin", "Pharmasist"],
  };

  getUser() {
    console.log("User:>");
    console.log(auth.getCurrentUser());
    return auth.getCurrentUser();
  }

  render() {
    const user = this.getUser();
    return (
      <React.Fragment>
        <ToastContainer />
        {/* <ToastContainer> */}
        <div style={{ height: "100vh" }}>
          <CookieBanner />
          <Navbar user={user} />
          {user !== null ? (
            <Switch>
              <Route path="/home" component={HomePage} />
              {/* <Route
                path="/pendingRegistration"
                component={FacilityRegistered}
              /> */}
              {/* <Route path="/patients" component={AdminPage} /> */}
              <Route path="/careers" component={Careers} />
              <Route path="/applicationSent" component={ApplicationSent} />
              {/* <Route path="/logout" component={Logout} /> */}
              {/* <Route
                path="/myProfile"
                component={() => <UserProfile user={user} />}
              />
              <Route path="/resetPassword" component={ResetPassword} /> */}
              <Redirect from="/" exact to="/home"></Redirect>
              <Redirect to="/home"></Redirect>
            </Switch>
          ) : (
            <Switch>
              <Route path="/home" component={HomePage} />
              {/* <Route path="/register" component={FacilityRegistrationPage} /> */}
              <Route path="/termsofuse" component={TermsOfUse} />
              <Route path="/privacypolicy" component={PrivacyPolicy} />
              <Route path="/applicationSent" component={ApplicationSent} />
              <Route path="/careers" component={Careers} />
              {/* <Route path="/login" component={Login} /> */}
              {/* <Route path="/resetPassword/auth" component={ResetPasswordAuth} /> */}
              {/* <Route path="/resetPassword" component={ResetPassword} /> */}
              <Redirect from="/" exact to="/home"></Redirect>
              <Redirect to="/home"></Redirect>
            </Switch>
          )}
          <Footer />
        </div>
        {/* </ToastContainer> */}
      </React.Fragment>
    );
  }
}

export default App;
