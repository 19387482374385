import httpService from "./httpService.js";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import { resolve } from "joi-browser";
const API_Endpoint_auth = "/auth";
const tokenKey = "tokenKeyCurenet";

httpService.setJWT(getJWT()); // this was a Bi-directional dependency between authService and httpService, got rid of it via a fun call

export async function login(userKey, password) {
  await httpService
    .post(API_Endpoint_auth, {
      userKey,
      password,
    })
    .then((res) => {
      const jwt = res.data;
      localStorage.setItem(tokenKey, jwt); // storing JWT in local storage to  use it for authentication
    })
    .catch((e) => {
      localStorage.setItem(tokenKey, null);
    });
}

export function loginWithJWT(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

export function logout() {
  localStorage.removeItem(tokenKey);
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export function getJWT() {
  let ls = null;
  try {
    ls = window.localStorage;
  } catch (e) {
    alert(
      "No cookie permission, Please Allow third party cookies for this site to work properly"
    );
  }
  if (ls) return localStorage.getItem(tokenKey);
}

export default {
  login,
  loginWithJWT,
  logout,
  getCurrentUser,
  getJWT,
};
