import React, { Component } from "react";
import { Card } from "react-bootstrap";
import MailSent from "../../assets/images/MailSent.png";
class FormSent extends Component {
  state = {};

  render() {
    return (
      <div style={{ background: "#FEFEFE", paddingTop: "50px" }}>
        <p style={{ margin: "20px 100px 20px 100px", background: "#FEFEFE" }}>
          <b>
            <h2>
              We have received your Form and we will get back to you as soon as
              we can.
            </h2>
            <h5>
              Thank you for reaching us, We hope you are having a good day!!
            </h5>
          </b>
        </p>
        <Card>
          <Card.Img
            src={MailSent}
            alt="Card image"
            style={{ width: "100%", height: "90%", borderWidth: 0 }}
          />
        </Card>
      </div>
    );
  }
}

export default FormSent;
