import React, { Component } from "react";
import UserProfileMenu from "./UserProfileMenu";
const Navbar = ({ user }) => {
  return (
    <React.Fragment>
      <header className="Navbar-header ">
        <div className="Navbar-header-Left">
          <h2 style={{ fontSize: "60px", marginTop: "5px", marginBottom: 0 }}>
            <a
              href="/home"
              style={{ content: "", textDecoration: "none", color: "#bfc7c9" }}
            >
              <b style={{ fontWeight: 500 }}>cure</b>
              <span style={{ fontWeight: 300 }}>net</span>
            </a>
          </h2>
        </div>
        <div className="Navbar-header-Right">
          <h4 style={{ marginTop: "30px", fontSize: "30px", marginBottom: 0 }}>
            <ul className="NavList">
              <li>
                <a href="/home#Inquire" className="NavLink">
                  Inquire
                </a>
              </li>
              {/* <li>
                <a href="/register" className="NavLink">
                  Register
                </a>
              </li>
              {user === null || user === undefined ? (
                <li>
                  <a href="/login" className="NavLink">
                    LogIn
                  </a>
                </li>
              ) : (
                ""
              )} 
              {user !== null && user !== undefined ? (
                <li>
                  <a href="/profile" className="NavLink">
                    {user.username}
                  </a>
                </li>
              ) : (
                ""
              )}

              {user !== null && user !== undefined ? (
                <li>
                  <UserProfileMenu />
                </li>
              ) : (
                ""
              )}
              */}
            </ul>
          </h4>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Navbar;
