import React, { Component } from "react";
import { Admin, Resource, DateInput } from "react-admin";
import Navbar from "../Navbar/Navbar";
import { useGetOne } from "react-admin";
import dataProvider from "./dataProvider";
import PatientsList from "./PatientsList";
import PatientEdit from "./PatientEdit";
import PatientShow from "./PatientShow";
import DateFilter from "./DateFilter";
import { createBrowserHistory } from "history";
const history = createBrowserHistory();
// const dataProvider = jsonServerProvider("https://jsonplaceholder.typicode.com");

const HomePage = () => {
  return (
    <React.Fragment>
      <Navbar />
      <Admin dataProvider={dataProvider} history={history}>
        {/* <Resource name="date" show={DateFilter}></Resource> */}
        <Resource
          name="patients"
          list={PatientsList}
          edit={PatientEdit}
          show={PatientShow}
        ></Resource>
        {/* create={PostCreate}  */}
      </Admin>
    </React.Fragment>
  );
};

export default HomePage;
