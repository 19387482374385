import React, { Component } from "react";

class ElemComponent extends Component {
  //   constructor(props) {
  //     super(props);
  //   }

  render() {
    return (
      <text
        className="form-control ContactUsFormElems"
        onClick={this.props.handleCalenderClick}
        ref={this.props.innerRef}
      >
        {this.props.selectedDate}
      </text>
    );
  }
}

export default React.forwardRef((props, ref) => (
  <ElemComponent innerRef={ref} {...props} />
));
