import React from "react";
import PropTypes from "prop-types";

const Select = ({ name, label, options, defaultValue, error, ...rest }) => {
  return (
    <div className="form-group">
      <label>
        <b>{label}</b>
      </label>
      <select name={name} {...rest} className="form-control">
        {/* Removed .name from defaultValue below for AddNewItem in medNet */}
        <option defaultValue>{defaultValue.name}</option>
        {options.map((option) => {
          return (
            <option key={option._id} value={option.name}>
              {option.name}
            </option>
          );
        })}
      </select>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};
Select.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.object.isRequired,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};
export default Select;
