// import React, { Component } from "react";
import React from "react";
import Form from "../common/form";
import Joi from "joi-browser";
import DatePicker from "react-datepicker";
import ElemComponent from "./refComp";
import {
  getCurrentDate,
  getFormattedDate,
  getHoursFromTime,
  getFormattedTime,
  getFormattedDateTime,
  getFormattedDateWithSlash,
  getCurrentDateWithSlash,
} from "../utils/myDateTime";
import { toast } from "react-toastify";
import httpService from "../../services/httpService";
import "react-datepicker/dist/react-datepicker.css";
import FormSent from "./FormSent";
class ContactUsForm extends Form {
  state = {
    data: {
      firstname: "",
      lastname: "",
      email: "",
      mobilenumber: "",
      inquiryMsg: "",
      // demoDateTime: "",
    },
    errors: {},

    errorMessage: {
      firstname: "First Name",
      lastname: "Last Name",
      email: "Email Address",
      mobilenumber: "Mobile Number",
      selectedTime: "Time",
      inquiryMsg: "Inquiry Message",
    },
    selectedDate: "",
    selectedTime: "",
    setMinTime: "",
    calenderOpen: false,
    calenderTimeOpen: false,
    contactUsTab: "Inquiry",
    isSubmitDisabled: false,
    formSent: false,
    textAreaCharCount: 0,
  };
  schemaInquiry = {
    firstname: Joi.string()
      .regex(/^[a-zA-Z]+$/)
      .required(),
    lastname: Joi.string()
      .regex(/^[ a-zA-Z]+$/)
      .required(),
    email: Joi.string().email().required(),
    // mobilenumber: Joi.string().min(10).max(10).required(),
    mobilenumber: Joi.number().min(1000000000).max(9999999999).required(),
    inquiryMsg: Joi.string()
      .regex(/^['!,.?/%$" A-Za-z0-9\s\r\n]+$/)
      .allow("")
      .max(250),
    // demoDateTime: Joi.string().allow(""),
  };
  schemaDemo = {
    firstname: Joi.string()
      .regex(/^[a-zA-Z]+$/)
      .required(),
    lastname: Joi.string()
      .regex(/^[ a-zA-Z]+$/)
      .required(),
    email: Joi.string().email().required(),
    mobilenumber: Joi.string().min(10).max(10).required(),
    inquiryMsg: Joi.string()
      .regex(/^['!,.?/%$" A-Za-z0-9\s\r\n]+$/)
      .allow("")
      .max(250),
    selectedTime: Joi.string()
      .regex(/((1[012]|0[1-9]):[0-5][0-9](\s)?(AM|PM))/)
      .required(),
    demoDateTime: Joi.string().allow(""),
  };
  componentDidMount = () => {
    this.setState({
      selectedDate: new Date(),
      selectedTime: "--:-- --",
      setMinTime: new Date().setHours(8),
    });
    this.setMinDate(new Date());
  };
  setMinDate = (date) => {
    if (new Date().toDateString() == new Date(date).toDateString()) {
      console.log("SELECTED DATE IS TODAY");
      let setMinTime = "09:00 AM";
      if (
        new Date().toTimeString() >
        new Date(new Date().setHours(14)).toTimeString()
      ) {
        setMinTime = new Date().setHours(14);
        this.setState({ selectedTime: "--:-- --" });
      } else setMinTime = new Date();
      this.setState({ setMinTime }, () => console.log(this.state.setMinTime));
    } else {
      this.setState({ setMinTime: new Date().setHours(8) });
    }
  };
  handlescheduledDateUpdate = (date) => {
    this.setState({ selectedDate: date }, () => {
      let selected = getFormattedTime(date);
      this.setMinDate(date);
      let current = getFormattedTime(new Date());
      console.log("At Update:  ");
      console.log(new Date(this.state.selectedTime));
      console.log(new Date(current));

      this.closeCalender();
    });
  };
  handlescheduledTimeUpdate = (time) => {
    console.log(" Time :");
    console.log(getFormattedTime(time));

    this.setState({ selectedTime: getFormattedTime(time) }, () => {
      this.closeCalender();
      console.log(getHoursFromTime(this.state.selectedTime));
    });
  };
  handleCalenderClick = () => {
    this.setState({ calenderOpen: !this.state.calenderOpen });
  };
  handleCalenderTimeClick = () => {
    this.setState({ calenderTimeOpen: !this.state.calenderTimeOpen });
  };
  closeCalender = () => {
    this.setState({ calenderOpen: false, calenderTimeOpen: false });
  };
  updateContactUsTab = (tab) => {
    this.setState({ contactUsTab: tab, errors: "", isSubmitDisabled: false });
  };

  doSubmit = async () => {
    // call the server, save the changes

    const demoDateTime = getFormattedDateTime(
      new Date(this.state.selectedDate).setHours(
        getHoursFromTime(this.state.selectedTime)
      )
    );
    // this.state.selectedDate + " " + this.state.selectedTime;
    console.log("demoDateTime :>> ");
    console.log(demoDateTime);
    try {
      this.setState({ isSubmitDisabled: true });
      console.log("formData : ");
      let data = {};
      if (this.state.contactUsTab == "Demo")
        data = { ...this.state.data, demoDateTime };
      else {
        data = { ...this.state.data };
      }

      console.log(data);
      //send post req to server
      this.submitContactUsForm(data);
    } catch (e) {
      console.log(
        "Error Code" + e.response + "Error while form submission:      " + e
      );
      this.setState({ isSubmitDisabled: false });
    }
  };
  handleTextAreaUpdate = (e) => {
    const data = { ...this.state.data };
    data["inquiryMsg"] = e.currentTarget.value;
    const textAreaCharCount = e.currentTarget.value.length;
    this.setState({ data });
    this.setState({ textAreaCharCount });
    console.log(e.currentTarget.value);
    console.log(process.env.Inquire_Form_Endpoint);
  };
  submitContactUsForm = async (formData) => {
    this.props.setLoading(true);
    let response = {};
    let url = "";
    if (Object.keys(formData).length == 5) url = "/inquiremore";
    if (Object.keys(formData).length == 6) url = "/scheduleademo";
    await httpService
      .post(url, formData)
      .then((res) => {
        // response = await axios.post(url, formData);
        console.log(res);
        this.props.setLoading(false);

        if (res.status == 200 || res.status == 204) {
          this.setState({ formSent: true });
        }
      })
      .catch((error) => {
        this.setState({ isSubmitDisabled: false });
        console.error(error);
        this.props.setLoading(false);

        if (error.response.status == 400) {
          toast.error("Please Enter Valid Email", {
            position: "bottom-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: false,
          });
        } else {
          toast.error("Error, Please Try Again!!", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: false,
          });
        }
      });
  };
  handleGoBack = () => {
    this.setState({ formSent: false });
  };
  render() {
    return (
      <>
        {this.state.formSent ? (
          <div>
            <FormSent />
            <button
              className="form-control btn btn-secondary"
              onClick={this.handleGoBack}
            >
              Go Back
            </button>
          </div>
        ) : (
          <div
            id="Inquire"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "center",
              margin: "auto",
              marginTop: "20px",
              marginBottom: "20px",
              maxWidth: "50vw",
              minWidth: "750px",
              border: "1px solid rgb(101, 85, 85)",
              borderRadius: "10px",
              borderColor: "transparent",
              background: "aliceblue",
            }}
          >
            {/* <button>Contact Us</button>
      <button>Schedule A Demo</button> */}
            {/* <text>Let's Connect</text> */}
            <div
              // className="ContactUsFormElems"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <button
                className="ContactUsButtonL"
                onClick={() => this.updateContactUsTab("Inquiry")}
              >
                Inquire More
              </button>
              {/* <button
                className="ContactUsButtonR"
                onClick={() => this.updateContactUsTab("Demo")}
              >
                Schedule A Demo
              </button> */}
            </div>
            <form
              style={{
                padding: "25px",
              }}
              onSubmit={(e) => this.handleSubmit(e)}
            >
              <div
                style={{
                  maxWidth: "50vw",
                  minWidth: "700px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div
                  className="ContactUsFormElems"
                  style={{
                    display: "flex",
                    gap: "20px",
                    justifyContent: "space-around",
                  }}
                >
                  {this.renderInput(
                    "firstname",
                    "First Name"
                    // "e.g: Jigneshbhai"
                  )}
                  {this.renderInput(
                    "lastname",
                    "Last Name"
                    // "e.g: Patel"
                  )}
                </div>
                <div className="ContactUsFormElems mr-3 ml-3">
                  {this.renderInput(
                    "email",
                    "Email Address"
                    // "e.g: Jigneshbhai@JpHospital.com"
                  )}
                </div>

                <div className="ContactUsFormElems mr-3 ml-3">
                  {this.renderInput(
                    "mobilenumber",
                    "Mobile Number",
                    // this.state.contactUsTab === "Demo"
                    //   ? "Mobile Number"
                    //   : "Mobile Number",
                    // "e.g: 9087858535",
                    "",
                    "number"
                  )}
                </div>
                <div
                  style={{ display: "grid", padding: "10px 15px 10px 15px" }}
                  className="ContactUsFormElems mr-3 ml-3"
                >
                  <textarea
                    className="form-control"
                    name="message"
                    rows="5"
                    cols="50"
                    placeholder="Please enter your message here (max 250 characters)."
                    onChange={this.handleTextAreaUpdate}
                  ></textarea>
                  character count: {this.state.textAreaCharCount}/250
                </div>

                {this.state.contactUsTab === "Demo" ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "20px",
                      justifyContent: "space-around",
                      marginBottom: "20px",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label htmlFor={"PickADate"}>
                        <b className="ContactUsFormElems">Pick A Date</b>
                      </label>
                      <DatePicker
                        selected={this.state.scheduledDate}
                        onChange={(date) =>
                          this.handlescheduledDateUpdate(date)
                        }
                        shouldCloseOnSelect={true}
                        dateFormatCalendar="dd LLL yyyy"
                        open={this.state.calenderOpen}
                        onClickOutside={(e) => this.closeCalender(e)}
                        todayButton="Pick Today"
                        name="PickADate"
                        id="PickADate"
                        dateFormat="ddMMyy"
                        minDate={new Date()}
                        default="today"
                        className="form-control"
                        customInput={
                          <ElemComponent
                            handleCalenderClick={this.handleCalenderClick}
                            // onKeyDown={this.handleCalenderClick}
                            selectedDate={getFormattedDateWithSlash(
                              this.state.selectedDate
                            )}
                          />
                        }
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label htmlFor={"PickADate"}>
                        <b className="ContactUsFormElems">Pick A Time</b>
                      </label>
                      <DatePicker
                        selected={this.state.scheduledTime}
                        onChange={(time) =>
                          this.handlescheduledTimeUpdate(time)
                        }
                        open={this.state.calenderTimeOpen}
                        onClickOutside={(e) => this.closeCalender(e)}
                        className="form-control"
                        showTimeSelectOnly
                        showTimeSelect
                        timeFormat="h:mm aa"
                        timeIntervals={60}
                        timeCaption="time"
                        dateFormat="h:mm aa"
                        minTime={this.state.setMinTime}
                        maxTime={new Date().setHours(14)}
                        customInput={
                          <ElemComponent
                            handleCalenderClick={this.handleCalenderTimeClick}
                            // onKeyDown={this.handleCalenderClick}
                            selectedDate={this.state.selectedTime}
                            // {
                            //   getFormattedDateWithSlash(
                            //   this.state.selectedDate
                            // )}
                          />
                        }
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div
                className="ContactUsFormElems"
                style={{ display: "flex", justifyContent: "center" }}
              >
                {this.renderErrorMessage(this.state.errorMessage)}
              </div>
              {this.state.contactUsTab === "Demo" ? (
                <div>
                  {this.state.isSubmitDisabled ? (
                    <button
                      id="Demo"
                      className="ContactUsFormElems ContactUsSubmitButton"
                      style={{ backgroundColor: "lightslategray" }}
                      disabled
                    >
                      Request Demo
                    </button>
                  ) : (
                    <button
                      id="Demo"
                      className="ContactUsFormElems ContactUsSubmitButton"
                      style={{ backgroundColor: "lightslategray" }}
                    >
                      Request Demo
                    </button>
                  )}
                </div>
              ) : (
                <div>
                  {this.state.isSubmitDisabled ? (
                    <button
                      id="Enquiry"
                      className="ContactUsFormElems ContactUsSubmitButton"
                      style={{ backgroundColor: "cornflowerblue" }}
                      disabled
                    >
                      Send Enquiry
                    </button>
                  ) : (
                    <button
                      id="Enquiry"
                      className="ContactUsFormElems ContactUsSubmitButton"
                      style={{ backgroundColor: "cornflowerblue" }}
                    >
                      Send Enquiry
                    </button>
                  )}
                </div>
              )}
            </form>
          </div>
        )}
      </>
    );
  }
}
export default ContactUsForm;
