import React, { Component } from "react";
import Joi from "joi-browser";
import Input from "./input";
import Select from "./select";

class Form extends Component {
  state = {
    data: {},
    errors: {},
    isRequiredFilled: !false,
  };
  // validate on submit
  validate = (id = "") => {
    console.log("At validate:");
    console.log(this.state.data);
    console.log("id: " + id);
    const t = this.state.selectedTime;
    const options = { abortEarly: false }; // By Default Joi terminates validation as soon as it finds an error.
    let result = null;

    console.log("Schema:");
    console.log(this.schema);
    console.log("schemaInquiry:");
    console.log(this.schemaInquiry);
    console.log("schemaDemo:");
    console.log(this.schemaDemo);
    if (id == "") result = Joi.validate(this.state.data, this.schema, options);
    //ID is to differentiate between two schemas used on inquiry form
    else {
      result = Joi.validate(
        id === "Demo"
          ? { ...this.state.data, selectedTime: t }
          : this.state.data,
        id === "Demo" ? this.schemaDemo : this.schemaInquiry,
        options
      );
    }

    //pass an object to be validated, schema, object to set options
    console.log("result: ");
    console.log(result);
    if (!result.error) return null;

    // mapping the complex JOI error details to our simplified errors object
    const errors = {};
    for (let item of result.error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  handleSubmit = (e) => {
    e.preventDefault(); // prevent default action of submitting a form to the server of a bootstrap form
    //before submitting the form we need to validate it.
    // if(e.nativeEvent.submitter.id)
    const id = e.nativeEvent.submitter.id;
    const errors = this.validate(id);

    this.setState({ errors: errors || {} }); // errors object can not be null, so always have it point to an empty object
    if (errors) {
      this.setState({ isRequiredFilled: false });
      return;
    }
    this.setState({ isRequiredFilled: true });
    this.doSubmit();
  };

  updateRequiredFilled = (e) => {
    console.log(this.state.errors);
  };

  handleChange = (e) => {
    const data = { ...this.state.data };

    if (e.currentTarget.name == "otp" && e.currentTarget.value.length > 4) {
      data[e.currentTarget.name] = e.currentTarget.value.slice(0, 4);
      e.currentTarget.value = e.currentTarget.value.slice(0, 4);
    } else if (
      e.currentTarget.name == "mobilenumber" &&
      e.currentTarget.value.length > 10
    ) {
      data[e.currentTarget.name] = e.currentTarget.value.slice(0, 10);
      e.currentTarget.value = e.currentTarget.value.slice(0, 10);
    } else {
      data[e.currentTarget.name] = e.currentTarget.value;
    }
    this.setState({ data });
  };

  renderErrorMessage(errorMessage) {
    const arry = Object.keys(this.state.errors).map((key) => errorMessage[key]);
    if (!this.state.isRequiredFilled)
      if (arry.length > 0)
        return (
          <small>
            <text style={{ color: "#DB2B39" }}>
              {"Please enter valid " + arry.join(", ")}
            </text>
          </small>
        );
  }

  renderButton(label, bsClass, disabled) {
    if (disabled)
      return (
        <button
          //   to disable button if the validation fails
          disabled
          className={bsClass}
        >
          {label}
        </button>
      );
    else return <button className={bsClass}>{label}</button>;
  }

  renderInput(name, label, placeholder, type = "text") {
    // default type is text, and we pass password which will override the type
    const { data, errors } = this.state;
    return (
      <Input
        type={type} // e.g: text/ password
        name={name} // e.g: username/ password
        label={label} // this will be used to display errors
        placeholder={placeholder}
        onChange={this.handleChange} // tracks changes goes with value property
        value={data[name]} //to store data object
        error={errors[name]} // has all errors if any occurs otherwise its an empty {}
        autoFocus={name === "patientName"}
      />
    );
  }

  renderSelect(name, label, options, defaultType = "") {
    const { data, errors } = this.state;
    return (
      <Select
        name={name}
        defaultValue={defaultType}
        label={label}
        options={options}
        onChange={this.handleChange}
        value={data[name]}
      />
    );
  }
}

export default Form;
