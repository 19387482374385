import axios from "axios";
import { toast } from "react-toastify"; //toast notifications

axios.interceptors.response.use(null, (error) => {
  // to Intercept Unexpected Errors and display generic error, //SucessfulResponse=null, Error
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    // this means its unexpected Error
    toast.error(error.message, {
      toastId: "UnExpectedError",
    });
  }
  return Promise.reject(error);
});

function setJWT(jwt) {
  //configuring default headers
  axios.defaults.headers.common["x-auth-token"] = jwt;
}
async function getIp() {
  axios.defaults.baseURL = process.env.REACT_APP_PUBLIC_API_URL;
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJWT,
  getIp,
};
