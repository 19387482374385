import React, { Component } from "react";
import { Card } from "react-bootstrap";
import MailSent from "../../assets/images/MailSent.png";
class ApplicationSent extends Component {
  state = {};

  handleGoBack = () => {
    window.location = "/home";
  };
  render() {
    return (
      <div style={{ background: "#FEFEFE" }}>
        <p style={{ margin: "20px 100px 20px 100px", background: "#FEFEFE" }}>
          <b>
            <h2>
              We have received your application and we are humbled that you are
              interested in working with us
            </h2>
            <h5>
              Please allow us 48 hours to review your application, we will get
              back to you as soon as we can.
            </h5>
            <h5>Keep Improving.</h5>
          </b>
        </p>
        <Card>
          <Card.Img
            src={MailSent}
            alt="Card image"
            style={{ width: "100%", height: "90%", borderWidth: 0 }}
          />
        </Card>
        <div>
          <button
            className="form-control btn btn-secondary"
            onClick={this.handleGoBack}
          >
            Go Back
          </button>
        </div>
      </div>
    );
  }
}

export default ApplicationSent;
