import React, { Component } from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import { toast } from "react-toastify";
import Openings from "./Openings";
import httpService from "../services/httpService";
import { ReactComponent as AttachFile } from "../assets/images/attach_file.svg";

class Careers extends Form {
  state = {
    data: {
      firstname: "",
      lastname: "",
      email: "",
      mobilenumber: "",
      inquiryMsg: "",
      position: "",
      resume: "",
      // demoDateTime: "",
    },
    errors: {},

    errorMessage: {
      firstname: "First Name",
      lastname: "Last Name",
      email: "Email Address",
      mobilenumber: "Mobile Number",
      selectedTime: "Time",
      inquiryMsg: "Inquiry Message",
      position: "Desired Position",
      resume: "Resume",
    },
    positions: [],
    defaultPosition: "",
    onBeforeUnload: true,
    showUserError: false,
    userErrorMessage: "",
    textAreaCharCount: 0,
  };
  schema = {
    firstname: Joi.string()
      .regex(/^[a-zA-Z]+$/)
      .required(),
    lastname: Joi.string()
      .regex(/^[ a-zA-Z]+$/)
      .required(),
    email: Joi.string().email().required(),
    mobilenumber: Joi.string().min(10).max(10).required(),
    inquiryMsg: Joi.string()
      .regex(/^['!,.?/%$" A-Za-z0-9\s\r\n]+$/)
      .allow("")
      .max(600),
    position: Joi.string().required(),
    resume: Joi.object().min(1).required(),
  };

  updatePositions = (openings) => {
    console.log("positions : ");
    console.log(openings);
    if (openings.length > 0) {
      const positions = openings.map((p) => ({
        _id: p.id,
        name: p.position,
      }));
      this.setState({ positions });
    }
  };

  handleTextAreaUpdate = (e) => {
    const data = { ...this.state.data };
    data["inquiryMsg"] = e.currentTarget.value;
    const textAreaCharCount = e.currentTarget.value.length;
    this.setState({ data });
    this.setState({ textAreaCharCount });

    console.log(e.currentTarget.value);
    console.log(process.env.Inquire_Form_Endpoint);
  };

  doSubmit = async () => {
    try {
      let data = this.state.data;
      delete data.confirmpassword;
      let fd = new FormData();

      fd.append("firstname", this.state.data.firstname);
      fd.append("lastname", this.state.data.lastname);
      fd.append("email", this.state.data.email);
      fd.append("mobilenumber", this.state.data.mobilenumber);
      fd.append("inquiryMsg", this.state.data.inquiryMsg);
      fd.append("position", this.state.data.position);

      for (const key of Object.keys(this.state.data.resume)) {
        fd.append("resume", this.state.data.resume[key]);
      }

      const headers = {
        "Content-Type": "multipart/form-data",
      };
      console.log("Resume");
      console.log(this.state.data.resume);
      await httpService
        .post("/openings", fd, { headers })
        .then((res) => {
          // await axios
          //   .post(openingsUrl, fd, { headers })
          //   .then((res) => {
          if (res.status == 200) {
            this.setState({ onBeforeUnload: false });

            // this.loginNewUser(authUrl);

            setTimeout(() => {
              window.location = `/applicationSent`;
            }, 3000);
          }
        })
        .catch((error) => {
          console.error(error);
          if (error.response && error.response.status === 400) {
            this.setState({ userErrorMessage: error.response.data });
          }
        });
    } catch (e) {
      console.error(
        "Error Code" + e.response + "Error while reg form submission:      " + e
      );
      if (e.response && e.response.status === 400) {
        console.log("User Already Registered:    " + e.response.data); // Need to Implement Already registered functionality
        //getting the Error from server
        this.setState({ isUsernameUnique: false }, () =>
          this.renderUsernameTaken()
        );

        return;
      }
    }
  };

  handleFileChange = (event) => {
    // console.log(event);
    // console.log(event.target.files);
    if (event.target.files.length > 0) {
      const resume = event.target.files;
      console.log("resume: ");
      console.log(resume);
      let data = { ...this.state.data, resume };
      this.setState({ data });
    }
  };

  attachFile = () => {
    this.setState({ clickPhotoShow: true });
    this.fileInput.click();
  };
  render() {
    if (this.state.onBeforeUnload)
      window.onbeforeunload = function () {
        return "Are you sure you want to leave?";
      };
    else {
      window.onbeforeunload = null;
      console.log("Not On before unload");
    }
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: "180px",
          overflowY: "scroll",
        }}
      >
        <Openings updatePositions={this.updatePositions} />

        <form
          style={{
            padding: "20px 40px 0 40px",
            margin: "0 80px 0 80px",
            justifyContent: "center",
            zoom: 1.1,
            width: "800px",
          }}
          onSubmit={(e) => this.handleSubmit(e)}
        >
          <b>
            <h2>Want to work with us?</h2>
            <h3>Fill out below details and we will get back to you.</h3>
          </b>

          <div
            className="ContactUsFormElems"
            style={{
              paddingTop: "10px",
              display: "flex",
              gap: "20px",
              justifyContent: "space-around",
            }}
          >
            {this.renderInput(
              "firstname",
              "First Name"
              // "e.g: John"
            )}
            {this.renderInput(
              "lastname",
              "Last Name"
              //  "e.g: Doe"
            )}
          </div>
          <div className="ContactUsFormElems mr-3 ml-3">
            {this.renderInput(
              "email",
              "Email Address"
              // "e.g: myemail@gmail.com"
            )}
          </div>
          <div className="ContactUsFormElems mr-3 ml-3">
            {this.renderInput(
              "mobilenumber",
              "Mobile Number",
              // "e.g: 9087858535"
              "",
              "number"
            )}
          </div>
          <div className="ContactUsFormElems mr-3 ml-3">
            {this.renderSelect(
              "position",
              "Select Your Desired Position",
              this.state.positions,
              this.state.defaultPosition
            )}
          </div>
          <div
            className="ContactUsFormElems mr-3 ml-3"
            id="resume"
            style={{
              display: "flex",
              flexDirection: "row",
              paddingBottom: "10px",
            }}
          >
            <b>Attach Resume</b>

            <AttachFile
              height="24px"
              onClick={() => this.attachFile("resume")}
            />
            {Object.entries(this.state.data.resume).length > 0 ? (
              <div>
                {Object.values(this.state.data.resume)
                  .map((e) => e.name)
                  .join(", ")}
              </div>
            ) : (
              "(Only Pdf and Image files are allowed)"
            )}
          </div>
          <div
            style={{ display: "grid", padding: "10px 15px 10px 15px" }}
            className="ContactUsFormElems mr-3 ml-3"
          >
            <b>Describe the toughest problem you came across so far</b>
            <b>and how you solved it.</b>
            <textarea
              name="message"
              rows="12"
              cols="50"
              placeholder="max 600 characters"
              onChange={this.handleTextAreaUpdate}
            ></textarea>
            character count: {this.state.textAreaCharCount}/600
          </div>
          <div
            className="ContactUsFormElems"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {this.renderErrorMessage(this.state.errorMessage)}
            {this.state.showUserError ? (
              <small>
                <div style={{ color: "#DB2B39" }}>
                  {this.state.userErrorMessage}{" "}
                </div>
              </small>
            ) : (
              ""
            )}
          </div>
          <div>
            {this.renderButton("APPLY", "btn btn-dark btn-lg btn-block")}
          </div>
          <div style={{ display: "none", justifyContent: "center" }}>
            <input
              type="file"
              multiple="multiple"
              onChange={this.handleFileChange}
              ref={(fileInput) => (this.fileInput = fileInput)}
              accept="image/png, image/gif, image/jpeg ,application/pdf"
            ></input>
          </div>
        </form>
      </div>
    );
  }
}

export default Careers;
