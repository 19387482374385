import React, { Component } from "react";
import PendingRegistration from "../../assets/images/UnderVerification.png";
import { Card } from "react-bootstrap";
class FacilityRegistered extends Component {
  state = {
    registrationData: null,
  };

  componentDidMount = () => {
    try {
      const registrationData = localStorage.getItem("registrationData");
      console.log(JSON.parse(registrationData));
      this.setState({ registrationData: JSON.parse(registrationData) });
    } catch (e) {
      console.error("No Cookie Access");
    }
  };

  render() {
    const registrationData = this.state.registrationData;
    return (
      <div className="container">
        <header className="App-header">Curenet Facility Registration</header>
        <div style={{ textAlign: "center" }}>
          <h3>Welcome to curenet</h3>
          <h5>
            Your Facility Registration is in Progress and We will get in touch
            with you shortly!!
          </h5>
          {registrationData == null ? (
            ""
          ) : (
            <div>
              Here are your details.
              <div style={{ display: "flex", justifyContent: "center" }}>
                <table>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: "left", paddingRight: "25px" }}>
                        FacilityId
                      </td>
                      <td style={{ textAlign: "left" }}>
                        {registrationData.facility_id}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "left", paddingRight: "25px" }}>
                        FacilityName
                      </td>
                      <td style={{ textAlign: "left" }}>
                        {registrationData.facility_name}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "left", paddingRight: "25px" }}>
                        FacilityType
                      </td>
                      <td style={{ textAlign: "left" }}>
                        {registrationData.facility_type}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "left", paddingRight: "25px" }}>
                        FacilityEmail
                      </td>
                      <td style={{ textAlign: "left" }}>
                        {registrationData.facility_email}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>

        <Card.Img
          src={PendingRegistration}
          alt="Card image"
          style={{
            width: "100%",
            height: "150%",
            paddingRight: "70px",
            borderWidth: 0,
          }}
        />
      </div>
    );
  }
}

export default FacilityRegistered;
