import React, { Component } from "react";
import { ReactComponent as Email } from "../../assets/images/emailLogo.svg";
import { ReactComponent as Phone } from "../../assets/images/phoneLogo.svg";

const Navbar = () => {
  return (
    <React.Fragment>
      <header className="Navbar-footer ">
        <ul className="footerList">
          <h5 style={{ color: "#bfc7c9", fontWeight: 600 }}>Contact Us</h5>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <li
              onClick={() => {
                navigator.clipboard.writeText("support@curenet.in");
                window.alert("Email Copied to the Clipboard!");
              }}
            >
              <Email height="25px" />
              <a className="NavLink">support@curenet.in</a>
            </li>
            {/* <li
              onClick={() => {
                navigator.clipboard.writeText("+91 9087858535");
                window.alert("Contact Number Copied to the Clipboard!");
              }}
            >
              <Phone height="25px" />
              <a className="NavLink">+91 9087858535</a>
            </li> */}
          </div>
        </ul>
        <ul className="footerList">
          <li>
            <h5 style={{ color: "#bfc7c9", fontWeight: 600 }}>Work With Us</h5>
          </li>
          {/* <li>
            <a href="/register" className="NavLink">
              Register Your Facility
            </a>
          </li> */}
          <li>
            <a href="/careers" className="NavLink">
              Careers
            </a>
          </li>
        </ul>
        <ul className="footerList">
          <li>
            <a href="/privacypolicy" className="NavLink">
              Privacy Policy
            </a>
          </li>
          <li>
            <a href="/termsofuse" className="NavLink">
              Terms Of Use
            </a>
          </li>
          <li>
            <text style={{ color: "#bfc7c9", fontWeight: 600 }}>
              All Rights Reserved, 2021
            </text>
          </li>
        </ul>
      </header>
    </React.Fragment>
  );
};

export default Navbar;
