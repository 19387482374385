import React, { useState } from "react";
import ContactUsForm from "./ContactUsForm";
import { Card } from "react-bootstrap";
import ReactTextTransition, { presets } from "react-text-transition";
import background1 from "../../assets/images/1 - medical-professionals-healthcare.jpg";
import background2 from "../../assets/images/3 - Save Earth.png";
import background3 from "../../assets/images/4 - DigitalHealthcare.jpg";
import background4 from "../../assets/images/5 - MobileCalenderReminder.png";
import background5 from "../../assets/images/5 - Medical-Store-Inventory.png";
import background6 from "../../assets/images/6 - Analise and Grow.png";
import background7 from "../../assets/images/7 - MapOnMobile.png";
import background8 from "../../assets/images/8 - Link-Medical_facilities.jpg";
import { useEffect } from "react";
import LoadingSpinner from "../common/loadingSpinner";

// textIndex: 0,
// const texts = ["IMAGING", "INVENTING", "DEFINING"];
const HomePage = () => {
  const [texts, setTexts] = useState(["IMAGINE", "INVENT", "DEFINE"]);
  const [loading, setLoading] = useState(true);

  let [textIndex, setTextIndex] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setTextIndex(textIndex + 1);
    }, 5000);
  }, [textIndex]);

  useEffect(() => {
    // Loading function to load data or
    // fake it using setTimeout;
    const loadData = async () => {
      // Wait for two second
      await new Promise((r) => setTimeout(r, 300));
      // Toggle loading state
      setLoading((loading) => !loading);
    };
    loadData();
  }, []);
  return (
    <div style={{ paddingBottom: "120px" }}>
      <div className="HomePage">
        <LoadingSpinner loading={loading} />

        <Card
          className="bg-dark text-white"
          // id="demotext"
          style={{ borderWidth: 0 }}
        >
          <Card.Img src={background1} alt="Card image" />
          <Card.ImgOverlay style={{ marginTop: "11vw", marginLeft: "9vw" }}>
            <Card.Title
              className="inline"
              id="demotext"
              style={{ fontWeight: 700, fontSize: "6vw" }}
            >
              Re
              <ReactTextTransition
                text={texts[textIndex % texts.length]}
                springConfig={{ tension: 300, friction: 10 }}
                inline
              />
            </Card.Title>
          </Card.ImgOverlay>
        </Card>
        {/* 
        <Card
          // className="text-white"
          style={{
            // backgroundColor: "#ACD5EB",
            borderWidth: 0,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Card.Img
            src={background2}
            alt="Card image"
            style={{
              width: "60%",
              height: "40%",
              borderWidth: 0,
            }}
          />
          <text
            // id="demotext"
            style={{
              fontWeight: 700,
              fontSize: "6.5vw",
              margin: "105px 0px 0px 0px",
              color: "#6B9160",
              lineHeight: "0.9",
            }}
          >
            Go Paperless For Greener Tomorrow
          </text>
        </Card> */}
        {/* <Card
          className="bg-dark text-white"
          style={{
            borderWidth: 0,
          }}
        >
          <Card.Img
            src={background3}
            alt="Card image"
            style={{ width: "100%", height: "90%", borderWidth: 0 }}
          />
          <Card.ImgOverlay style={{ marginLeft: "3vw" }}>
            <Card.Title
              className="inline"
              // id="demotext"
              style={{
                fontWeight: 700,
                fontSize: "7vw",
                lineHeight: "0.9",
                textAlign: "center",
                color: "#012E55",
              }}
            >
              Take Control Of Your Medical Records
            </Card.Title>
          </Card.ImgOverlay>
        </Card> */}
        <Card
          // className="text-white"
          style={{
            backgroundColor: "#FEFEFE",
            borderWidth: 0,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Card.Img
            src={background4}
            alt="Card image"
            style={{
              width: "60%",
              height: "50%",
              borderWidth: 0,
            }}
          />
          <text
            // id="demotext"
            style={{
              fontWeight: 700,
              fontSize: "6.5vw",
              margin: "95px 0px 0px",
              color: "#8079BC",
              lineHeight: "0.9",
              zoom: 0.9,
            }}
          >
            Online Appointment Booking System
          </text>
        </Card>
        {/* <Card
          className="bg-dark text-white"
          style={{
            borderWidth: 0,
          }}
        >
          <Card.Img
            src={background5}
            alt="Card image"
            style={{ width: "100%", height: "90%", borderWidth: 0 }}
          />
          <Card.ImgOverlay>
            <Card.Title
              className="inline"
              // id="demotext"
              style={{
                fontWeight: 700,
                fontSize: "7.5vw",
                textAlign: "center",
                color: "cadetblue",
                marginTop: "40px",
                lineHeight: "0.9",
              }}
            >
              Inventory Management With Helpful Insignts
            </Card.Title>
          </Card.ImgOverlay>
        </Card> */}
        {/* <Card
          // className="text-white"
          style={{
            backgroundColor: "#FEFEFE",

            borderWidth: 0,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Card.Img
            src={background6}
            alt="Card image"
            style={{
              width: "60%",
              height: "50%",
              borderWidth: 0,
            }}
          />
          <text
            // id="demotext"
            style={{
              fontWeight: 700,
              fontSize: "6.5vw",
              margin: "95px 10px 0px",
              color: "#8079BC",
              lineHeight: "0.9",
            }}
          >
            Analyse Better Improve Faster
          </text>
        </Card> */}

        <Card
          // className="text-white"
          style={{
            backgroundColor: "#FEFEFE",
            borderWidth: 0,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Card.Img
            src={background8}
            alt="Card image"
            style={{
              width: "55%",
              height: "70%",
              borderWidth: 0,
            }}
          />
          <text
            // id="demotext"
            style={{
              fontWeight: 700,
              fontSize: "6.5vw",
              margin: "70px 5px 10px 0px",
              color: "#3A77A6",
              lineHeight: "0.9",
              zoom: 0.9,
            }}
          >
            {/* Link Facilities And Collaborate Seamlessly */}
            Showcase Your Services To The World
          </text>
        </Card>
        <Card
          // className="text-white"
          style={{
            backgroundColor: "#FEFEFE",
            borderWidth: 0,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <text
            // id="demotext"
            style={{
              fontWeight: 700,
              fontSize: "6.5vw",
              margin: "90px 10px 10px 60px",
              color: "rgb(112, 200, 194)",
              lineHeight: "0.9",
              zoom: 0.9,
            }}
          >
            Make Your Facility Easily Reachable
          </text>
          <Card.Img
            src={background7}
            alt="Card image"
            style={{
              width: "60%",
              height: "50%",
              borderWidth: 0,
            }}
          />
        </Card>
        {/* <h3>
          <b>
            <div>Cure Livings With Healthcare Redifining Network</div>
            <div>
              Register Your Medical Facility And Join The Transformation
            </div>
            <div>Go Paperless For Greener Tomorrow</div>
            <div>Take Control Of Your Medical Records</div>
            <div>Keep your data secure</div>
            <div>Send Remiders/Alert To Your Patients</div>
            <div>Manage Your Inventory With Helpful Insignts</div>
            <div>Analyse And Improve As You Cure</div>
            <div>Find Verified Medical Facilities Near You</div>
            <div>Link Facilities And Seamlessly Collaborate</div>
          </b>
        </h3> */}
      </div>

      <ContactUsForm loading={loading} setLoading={setLoading}></ContactUsForm>
    </div>
  );
};

export default HomePage;
