import React, { useState, useMemo } from "react";
import { useEffect } from "react";
import { useTable, useExpanded } from "react-table";
import httpService from "../services/httpService";

// import "react-table/react-table.css";
import { ListGroup } from "react-bootstrap";

const Openings = (props) => {
  const [openings, setOpenings] = useState([]);
  const [expandIcon, setExpandIcon] = useState("> ");

  // const [data, setData] = useState([]);
  // const [columns, setColumns] = useState([]);

  useEffect(async () => {
    await httpService.get("/openings").then((res) => {
      console.log(res);
      setOpenings(res.data);
      // setUserDataEntries(Object.entries(res.data));
      props.updatePositions(res.data);
      console.log(openings);
    });
  }, []);

  const data = useMemo(
    () => [
      ...openings.map((opening) => ({
        col1: `${expandIcon}${opening.position}`,
        col2: opening.openings,
      })),
    ],
    [openings]
  );
  const columns = useMemo(
    () => [
      {
        Header: "Position",
        accessor: "col1",
        maxWidth: 200,
      },
      {
        Header: "Openings",
        accessor: "col2", // accessor is the "key" in the data
        maxWidth: 300,
      },
    ],
    []
  );
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useExpanded
  );
  return (
    <div style={{ display: "flex", justifyContent: "center", zoom: 1.2 }}>
      <table {...getTableProps()} style={{ margin: 20 }}>
        <ListGroup style={{ width: "700px" }}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                <ListGroup horizontal>
                  {headerGroup.headers.map((column) => (
                    <ListGroup.Item style={{ width: 350 }}>
                      <th style={{ width: column.maxWidth }}>
                        {column.render("Header")}
                      </th>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              if (row.isExpanded) {
                // setExpandIcon("> ");
                return (
                  <ListGroup>
                    <tr
                      {...row.getRowProps()}
                      {...row.getToggleRowExpandedProps()}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: 500,
                        justifyItems: "space-between",
                      }}
                    >
                      <ListGroup horizontal>
                        {row.cells.map((cell) => {
                          return (
                            <ListGroup.Item
                              action
                              variant="light"
                              style={{
                                width: 350,
                                fontWeight: 600,
                                color: "black",
                              }}
                            >
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            </ListGroup.Item>
                          );
                        })}
                      </ListGroup>
                    </tr>
                    <ListGroup.Item>
                      <ListGroup>
                        <ListGroup.Item>
                          <tr style={{ width: "500px" }}>
                            <td style={{ width: "200px", fontWeight: 500 }}>
                              Eligibility
                            </td>
                            <td>
                              {
                                openings.find(
                                  (o) =>
                                    o.position ==
                                    row.values.col1.replace(expandIcon, "")
                                ).eligibility
                              }
                            </td>
                          </tr>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <tr style={{ width: "500px" }}>
                            <td style={{ width: "200px", fontWeight: 500 }}>
                              Responsibilities
                            </td>
                            <td>
                              {
                                openings.find(
                                  (o) =>
                                    o.position ==
                                    row.values.col1.replace(expandIcon, "")
                                ).responsibilities
                              }
                            </td>
                          </tr>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <tr style={{ width: "500px" }}>
                            <td style={{ width: "200px", fontWeight: 500 }}>
                              Skills Required
                            </td>
                            <td>
                              {
                                openings.find(
                                  (o) =>
                                    o.position ==
                                    row.values.col1.replace(expandIcon, "")
                                ).skills_required
                              }
                            </td>
                          </tr>
                        </ListGroup.Item>
                      </ListGroup>
                    </ListGroup.Item>
                  </ListGroup>
                );
              } else {
                return (
                  <tr
                    {...row.getRowProps()}
                    {...row.getToggleRowExpandedProps()}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: 500,
                      justifyItems: "space-between",
                    }}
                  >
                    <ListGroup horizontal>
                      {row.cells.map((cell) => {
                        return (
                          <ListGroup.Item
                            action
                            variant="light"
                            style={{
                              width: 350,
                              fontWeight: 600,
                              color: "black",
                            }}
                          >
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          </ListGroup.Item>
                        );
                      })}
                    </ListGroup>
                  </tr>
                );
              }
            })}
          </tbody>
        </ListGroup>
      </table>
    </div>
  );
};

export default Openings;
